
  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import LzTable from "@/components/Table.vue";
  import LzStepper from "@/components/Stepper.vue";
  import { namespace } from "vuex-class";

  const auth = namespace("auth");

  @Component({ components: { LzButton, LzTable, LzStepper } })
  export default class Read extends Vue {
    @auth.State("id")
    public ongId!: string;

    loaded = true;

    profileForm = {
      img: null,
      name: "",
      surname: "",
      email: "",
      phone: "",
      newPassword: "",
      discountCode: ""
    };
  }
